
function PredictTextBaseOnQuesstion() {

    return (
        <div className='row'>
            <div className='col-md-2'>
                <label className='mb-md-2 mt-lg-2'>Kết quả dự đoán</label>
            </div>
            <div className='col-md-8'>
                <input
                    className='form-control'
                    disabled
                    // value={value}
                />
            </div>
            <div className='col-md-1'>
            </div>
        </div>
    )
}

export default PredictTextBaseOnQuesstion